<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >

    <template #button-content>
      <b-row>
        <b-col>
          <div class="user-nav font-weight-bolder justify-content-center align-content-center">
            <span class="mb-50">{{ getUserProfileName() }}</span>
            <small>{{ getEmail() }}</small>
          </div>
        </b-col>
      </b-row>
      <b-avatar size="40" :src="getAvatar" :text="getAvatar" variant="light-primary" badge class="badge-minimal user-avatar" badge-variant="success">
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22"/>
      </b-avatar>
    </template>
    <b-dropdown-item>
      <span
          class="ml-50">{{
          getUserProfileName().length > 13 ? getUserProfileName().slice(0, 13) + '...' : getUserProfileName()
        }}</span>
    </b-dropdown-item>

    <b-dropdown-divider/>

    <!--    <template>-->
    <!--      <div class="d-sm-flex d-none user-nav">-->
    <!--        <p class="user-name font-weight-bolder mb-0">-->
    <!--          {{ userData.fullName }}-->
    <!--        </p>-->
    <!--        &lt;!&ndash;        <span class="user-status">{{ userData.role }}</span>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </template>-->

    <b-dropdown-item :to="{ name: 'pages-profile'}"
                     link-class="d-flex align-items-center">
      <feather-icon size="16"
                    icon="UserIcon"
                    class="mr-50"/>
      <span>{{ $t('profile') }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-account-setting' }"
                     link-class="d-flex align-items-center">
      <feather-icon size="16"
                    icon="SettingsIcon"
                    class="mr-50"/>
      <span>{{ $t('settings') }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-faq' }"
                     link-class="d-flex align-items-center">
      <feather-icon size="16"
                    icon="HelpCircleIcon"
                    class="mr-50"/>
      <span>{{ $t('FAQ') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center"
                     @click="logout">
      <feather-icon size="16"
                    icon="LogOutIcon"
                    class="mr-50"/>
      <span>{{ $t('logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown, BRow, BCol} from 'bootstrap-vue'
import {initialAbility} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import {avatarText} from '@core/utils/filter'
import useWorkspacesList from "@/views/Workspace/List/useWorkspacesList";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow, BCol
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      organizationList: [{
        id: "",
        name: JSON.parse(localStorage.getItem('userData')).email
      }]
    }
  },
  mounted() {
    this.fetchOrganizations()
  },
  computed: {
    getAvatar() {
      return this.userData.avatar === null ? this.userData.initials : this.userData.avatar
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({name: 'Auth-login'})
    },
    fetchOrganizations() {
      const {
        fetchMyWorkspaces
      } = useWorkspacesList()

      fetchMyWorkspaces("", (organizations) => {
        if (organizations.length !== 0) {
          organizations.map(org => {
            this.organizationList.push({
              id: org.organizationId,
              name: org.name,
            })
          })
        }
      })
    },
    getCurrentOrganization() {
      let userData = JSON.parse(localStorage.getItem('userData'));
      return {
        id: userData.defaultOrganizationId === null ? "" : userData.defaultOrganizationId,
        name: userData.defaultOrganizationName === null ? userData.email : userData.defaultOrganizationName
      }
    },
    getUserProfileName() {
      return JSON.parse(localStorage.getItem('userData')).fullName
    },
    getEmail() {
      return JSON.parse(localStorage.getItem('userData')).email
    },
    setOrganization(organization) {
      this.$store.dispatch("organizations/setDefaultOrganization", organization.id)

      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.defaultOrganizationId = organization.id
      userData.defaultOrganizationName = organization.name

      localStorage.setItem('userData', JSON.stringify(userData))

      if (organization.id === "") {
        this.$router.push({name: 'my-documents'})
      } else {
        this.$router.push({name: 'shared-documents'})
      }
    }
  },
}
</script>

<style lang="scss">
	.user-avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		.badge {

		}
	}
</style>