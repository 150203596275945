<template>
  <b-nav-item-dropdown id="dropdown-grouped"
                       variant="link"
                       class="dropdown-language"
                       right>
    <template #button-content>
      <b-img
          :src="currLocale.img"
          height="14px"
          width="22px"
          :alt="currLocale.locale"
      />
      <span class="ml-50 text-body">{{ currLocale.name }}</span>
    </template>
    <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="updateLocale(localeObj)"
    >
      <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BDropdownItem, BImg, BNavItemDropdown} from 'bootstrap-vue'
import {PEventBus} from "@/services/PEventBus";
import {locales} from "@/libs/locales";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userData"))
    this.currLocale = this.locales.filter(lang => lang.value === userInfo.preferredLanguage)[0]
  },
  data() {
    return {
      currLocale: null
    }
  },
  setup() {
    return {
      locales,
    }
  },
  methods: {
    updateLocale(locale) {
      this.currLocale = this.locales.filter(lang => lang.value === locale.value)[0]

      // update backend
      this.$store.dispatch("user/updatePreferredLanguage", locale.value)

      // UI
      localStorage.setItem('language', locale.locale)
      PEventBus.$emit("locale-updated")
      this.$i18n.locale = locale.locale
    }
  }
}
</script>

<style>

</style>
