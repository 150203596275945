/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Kontrol Paneli', route: { name: 'dashboard' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Gelenler', route: { name: 'inbox' }, icon: 'InboxIcon', isBookmarked: false },
      { title: 'Gidenler', route: { name: 'inbox-sent' }, icon: 'SendIcon', isBookmarked: false },
      { title: 'Kapatılanlar', route: { name: 'inbox-closed' }, icon: 'CheckIcon', isBookmarked: false },
      { title: 'Belgelerim', route: { name: 'my-documents' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Paylaşılanlar', route: { name: 'shared-documents' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Şablonlar', route: { name: 'templates' }, icon: 'LayoutIcon', isBookmarked: false },
      { title: 'Arşiv', route: { name: 'archived-documents' }, icon: 'ArchiveIcon', isBookmarked: false },
      { title: 'Değişken Yönetimi', route: { name: 'vars' }, icon: 'HashIcon', isBookmarked: false },
      { title: 'Çalışma Alanları', route: { name: 'organizations' }, icon: 'GridIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: '',
        size: '1.7 mb',
      }
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: '',
        name: 'Rena Brant',
        email: 'nephrod@preany.co.uk',
        time: '21/05/2019',
      }
    ],
  },
}
/* eslint-enable */
