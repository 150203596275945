export default ($i18n) => [
  {
    header: $i18n.t('menu.documentManagement'),
  },
  {
    title: $i18n.t('menu.my-documents'),
    route: 'my-documents',
    icon: 'FileTextIcon',
  },
  {
    title: $i18n.t('menu.shared-documents'),
    route: 'shared-documents',
    icon: 'UsersIcon',
  },
  {
    title: $i18n.t('menu.templates'),
    route: 'templates',
    icon: 'LayoutIcon',
  },
  {
    title: $i18n.t('menu.archive'),
    route: 'archived-documents',
    icon: 'ArchiveIcon',
  },

]
