<template>
    <layout-editor>
        <router-view />
    </layout-editor>
</template>

<script>
  import LayoutEditor from "@core/layouts/layout-editor/LayoutEditor";
  export default {
    name: "EditorLayout",
    components: {LayoutEditor}
  }
</script>

<style scoped>

</style>