export default ($i18n) => [
  {
    header: $i18n.t('menu.organizationManagement'),
  },
  {
    title: $i18n.t('menu.vars'),
    route: 'vars',
    icon: 'HashIcon',
  },
  {
    title: $i18n.t('menu.organizations'),
    route: 'organizations',
    icon: 'GridIcon',
  },
]
