export default ($i18n) => [
    {
        header: $i18n.t('menu.actions'),
    },
    {
        title: $i18n.t('menu.reviews'),
        route: 'inbox',
        icon: 'InboxIcon',
    },
    {
        title: $i18n.t('menu.sent'),
        route: 'inbox-sent',
        icon: 'SendIcon',
    },
    {
        title: $i18n.t('menu.done'),
        route: 'inbox-closed',
        icon: 'CheckIcon',
    },
]
