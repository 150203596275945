/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import actions from './actions'
import documents from './docs'
import organizations from './organizations'
import dashboard from './dashboard'

export default ($i18n) => {
  return [...dashboard($i18n), ...actions($i18n), ...documents($i18n), ...organizations($i18n)]
}

// Array of sections
// export default [...home, ...documents, ...companies]
